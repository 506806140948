<template>
  <div class="player-container">
    <div id="player" ref="player">
      <transition mode="out-in" name="fade" appear>
        <div v-if="playState < 4" class="curtain" style="background-color: black" :style="{ backgroundImage: 'url(' + thumb + ')' }">
          <transition mode="out-in" name="fade" appear>
            <playCircle v-if="playState === 1" class="icon play-icon" :size="60" @click="startPlayer()" />
          </transition>
          <div v-if="unsupportedBrowser && playState < 2" class="browser-warning">
            Your browser is not currently supported. It may work but we recommend the latest Chrome or Edge for best performance.
          </div>
          <transition mode="out-in" name="fade">
            <div v-if="playState !== 1" class="loading">
              <Circle2 class="icon" background="#ffffff" />
              <p v-if="playState > 1">Starting 3D experience</p>
              <p v-if="queue" style="margin-top: 0;">Est. {{ queue }} minute wait</p>
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <div class="fullscreen-icon" @click="toggleFullscreen()">
      <fullscreenIcon />
    </div>
  </div>
</template>

<script>
import { Player } from 'furioos-sdk';
import { isMobile, isChrome, isEdge, isFirefox, isSafari, browserName, browserVersion } from 'mobile-device-detect';
import playCircle from 'vue-material-design-icons/PlayCircle.vue';
import { Circle2 } from 'vue-loading-spinner';
import fullscreenIcon from 'vue-material-design-icons/Fullscreen.vue';

export default {
  name: 'Player',
  components: {
    playCircle,
    fullscreenIcon,
    Circle2
  },
  data () {
    return {
      player: undefined,
      playerOptions: {
        whiteLabel: true,
        hideToolbar: true,
        hideTitle: true,
        hidePlayButton: false
      },
      supportedBrowsers: {
        chrome: isChrome && parseInt(browserVersion) >= 75,
        edge: isEdge && parseInt(browserVersion) >= 80,
        firefox: isFirefox && parseInt(browserVersion) >= 70,
        safari: !isMobile && isSafari && parseInt(browserVersion) >= 12,
        mobileSafari: isMobile && isSafari && parseInt(browserVersion) >= 13,
        mobileChrome: isMobile && isChrome && parseInt(browserVersion) >= 85,
      },
      videoState: 0,
      playState: 0,
      fullscreen: false,
      visited: false,
      retryButton: false,
      queue: undefined,
      gameStarted: false
    }
  },
  mounted () {
    this.sendMessage({type: 'message', data: 'Initialising...'})
    this.player = new Player(this.gameId, 'player', this.playerOptions)
    this.player.onLoad(() => {
      this.sendMessage({type: 'message', data: 'Player Ready'})
      this.playState = 1
      setTimeout(() => { this.getQueueLength() }, 30000) // check queue after 30 seconds
    })
    this.player.onStreamStart(() => {
      this.startGame()
    })
    this.player.onUserActive(() => {
      this.playState = 3
    })
    // receive message from game
    this.player.onSDKMessage(event => {
      if (JSON.parse(event).data === 'gameReady') {
        this.playState = 4
        this.gameStarted = true
        console.log('Game Running')
      } else {
        this.sendMessage(JSON.parse(event))
      }
    })
    // receive message from parent
    window.addEventListener('message', event => {
      this.handleMessageFromParent(event)
    })
    // display browser and version
    console.log('Browser: ' + browserName + ', Version: ' + browserVersion + ', Supported: ' + !this.unsupportedBrowser)
  },
  computed: {
    gameId () {
      return this.$route.query.gameId
    },
    thumb () {
      return this.$route.query.thumb
    },
    unsupportedBrowser () {
      return Object.values(this.supportedBrowsers).every((value) => value === false)
    }
  },
  methods: {
    startPlayer () {
      this.playState = 2
      this.player.start()
    },
    startGame () {
      if (!this.gameStarted) {
        console.log('Trying game start')
        setTimeout(() => {
          this.initGameVars()
          this.startGame()
        }, 2000)
      }
    },
    initGameVars () {
      localStorage.setItem('Visited', true)
      isMobile ? this.player.sendSDKMessage('isMobile') : this.player.sendSDKMessage('isPC')
      if (localStorage.getItem('Visited')) {
        this.player.sendSDKMessage('canSkip')
      }
    },
    getQueueLength () {
      if (this.playState < 3) {
        setTimeout(() => {
          this.player.getServerAvailability(data => {
            this.queue = data.assignTime + data.launchTime
          })
          this.getQueueLength()
        }, 10000)
      }
    },
    sendMessage (message) {
      window.parent.postMessage({
        xpanse_message_output: message
      }, '*')
    },
    handleMessageFromParent (event) {
      if (!event.data.xpanse_message_input) {
        return
      } else {
        this.player.sendSDKMessage(event.data.xpanse_message_input)
        console.log('sending ' + event)
      }
    },
    toggleFullscreen () {
      this.fullscreen = !this.fullscreen
      window.parent.postMessage({
        xpanse_message_output: {type: 'toggleFullscreen', data: isMobile}
      }, '*')
    }
  }
}
</script>

<style lang="scss">
.player-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  .block {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.5);
  }

  #player, iframe, .curtain {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-width: 0px;
    z-index: 999;
  }

  .curtain, .loading {
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 100;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    color: white;

    .icon {
      width: 60px;
      height: 60px;
      color: white;
      z-index: 999;
      font-size: 60px;
      line-height: 60px;

      .material-design-icon__svg {
        width: 60px;
        height: 60px;
        bottom: 0;
      }
    }

    .browser-warning {
      z-index: 99999;
      background: #FFD265;
      color: black;
      line-height: 1;
      padding: 10px 20px;
      border-radius: 5px;
      position: absolute;
      width: 70%;
      text-align: center;
      bottom: 10%;
      left: 50%;
      margin-left: -35%;
    }

    p {
      font-size: 1em;
      display: block;
      margin-top: 2em;
      user-select: none;
    }

    .splash-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .skip {
      position: absolute;
      bottom: 10px;
      right: 100px;
    }
  }

  .play-icon {
    cursor: pointer;
  }

  .fullscreen-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    color: white;
    z-index: 9999;
    cursor: pointer;
  }
}
</style>
